import React from 'react';
import { FormattedMessage } from 'react-intl';
import { container, title, detail } from './cardInfoTable.module.scss';

interface TableRow {
	title: JSX.Element;
	detail: JSX.Element | boolean;
}

export const genericCardInfo: TableRow[] = [
	{
		title: <FormattedMessage id="table.price" defaultMessage="Price" />,
		detail: <FormattedMessage id="free" defaultMessage="Free" />,
	},
	{
		title: <FormattedMessage id="table.iban" defaultMessage="IBAN account" />,
		detail: true,
	},
	{
		title: <FormattedMessage id="table.sepa" defaultMessage="SEPA transfers" />,
		detail: true,
	},
	{
		title: <FormattedMessage id="table.swift" defaultMessage="SWIFT transfers" />,
		detail: true,
	},
	{
		title: <FormattedMessage id="table.crypto" defaultMessage="Cryptocurrency" />,
		detail: true,
	},
];

export const CardInfoTable: React.FC<{ rows: TableRow[] }> = ({ rows }) => {
	return (
		<table className={container}>
			<tbody>
				{rows.map((row) => {
					return (
						<tr key={row.title.props.id}>
							<td className={title}>{row.title}</td>
							<td className={detail}>
								{typeof row.detail === 'boolean' ? (
									row.detail ? (
										<FormattedMessage id="table.yes" defaultMessage="Yes" />
									) : (
										<FormattedMessage id="table.no" defaultMessage="No" />
									)
								) : (
									row.detail
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default CardInfoTable;
