import React from 'react';
import classNames from 'classnames';
import { center } from './limitedWidth.module.scss';

interface Props {
	maxWidth: number;
	centerBlock?: boolean;
}

const LimitedWidth: React.FC<Props> = ({ children, maxWidth: maxwidth, centerBlock }) => {
	if (maxwidth < 0) {
		throw new Error('Bad maxWidth supplied to the LimitedWidth component');
	}

	return (
		<div style={{ maxWidth: maxwidth }} className={classNames({ [center]: centerBlock })}>
			{children}
		</div>
	);
};

export default LimitedWidth;
