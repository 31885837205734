import React from 'react';
import BankeraContainer from 'src/components/CompanySides/BankeraSide';
import MetaTags from 'src/components/MetaTags';
import SpectroCoinSide from 'src/components/CompanySides/SpectroCoinSide';
import SplitScreenContainer from 'src/components/SplitScreenContainer/SplitScreenContainer';
import PropsMeta from 'src/interfaces/PropsMeta';
import Header from 'src/components/Header/Header';

const Index: React.FC<PropsMeta> = ({ location }) => {
	return (
		<>
			<MetaTags
				data={{
					keywords: 'bitcoin debit card, prepaid card, crypto card, free debit card, european iban',
					og: { urlPathname: location.pathname },
				}}
			/>
			<Header />
			<SplitScreenContainer onlyOnDesktop>
				<SpectroCoinSide />
				<BankeraContainer />
			</SplitScreenContainer>
		</>
	);
};

export default Index;
