import React from 'react';
import LimitedWidth from 'src/components/LimitedWidth/LimitedWidth';
import logoSrc from 'src/images/vector/bnk-logo.svg';
import cardSrc from 'src/images/raster/bnk-card.png';
import classNames from 'classnames';
import listSrc1 from 'src/images/vector/bnk-do-not-limit.svg';
import listSrc2 from 'src/images/vector/bnk-spend-around-globe.svg';
import listSrc3 from 'src/images/vector/bnk-receive-right-away.svg';
import { FormattedMessage } from 'react-intl';
import richFormattingValues from 'src/helpers/RichFormattingValues';
import {
	container,
	bankeraBackground,
	logo,
	card,
	textBox,
	bnkButton,
	listItem,
	list,
	orderButton,
	footer,
} from './companySides.module.scss';
import CardInfoTable, { genericCardInfo } from '../CardInfoTable/CardInfoTable';

const BankeraSide: React.FC = () => {
	const tableRows = genericCardInfo.map((a) => ({ ...a })); // Clone
	tableRows[4].detail = false;

	return (
		<div className={classNames(bankeraBackground, container)}>
			<img src={logoSrc} alt="Bankera logo" className={logo} />
			<img src={cardSrc} alt="Bankera card" className={card} />
			<LimitedWidth maxWidth={426} centerBlock>
				<p className={textBox}>
					<FormattedMessage
						id="sides.bankera.introduction"
						defaultMessage="If you want to enhance your fiat spending experience – 
						get your Visa debit card with a European IBAN account at Bankera. Receive 
						your physical card straight to the mailbox or start spending instantly with a virtual card. For free."
					/>
				</p>
				<a href="https://bankera.com/" className={bnkButton}>
					<FormattedMessage id="sides.bankera.button.card" defaultMessage="Get Your Bankera Card" />
				</a>
				<div className={list}>
					<div className={listItem}>
						<div>
							<img src={listSrc1} alt="Paper airplane with a coin" />
						</div>
						<p>
							<FormattedMessage
								id="sides.bankera.list.doNotLimit"
								defaultMessage="<b>Do not limit yourself.</b> Take advantage of high spending and cash withdrawal limits to meet your needs."
								values={richFormattingValues}
							/>
						</p>
					</div>
					<div className={listItem}>
						<div>
							<img src={listSrc2} alt="Flat globe" />
						</div>
						<p>
							<FormattedMessage
								id="sides.bankera.list.spendArondGlobe"
								defaultMessage="<b>Spend around the globe.</b> Pay at millions of shops where Visa is accepted and enjoy excellent exchange rates."
								values={richFormattingValues}
							/>
						</p>
					</div>
					<div className={listItem}>
						<div>
							<img src={listSrc3} alt="Green payment card with contactless function" />
						</div>
						<p>
							<FormattedMessage
								id="sides.bankera.list.receiveRightAway"
								defaultMessage="<b>Receive right away.</b> Get a virtual Visa card to start spending online in seconds."
								values={richFormattingValues}
							/>
						</p>
					</div>
				</div>
				<CardInfoTable rows={tableRows} />
				<a href="https://bankera.com/" className={classNames(bnkButton, orderButton)}>
					<FormattedMessage id="button.order" defaultMessage="Order now" />
				</a>
				<p className={classNames(footer, 'showBelowTablet')}>
					<FormattedMessage
						id="footer.text"
						defaultMessage="Copyright © 2013-2021 Spectro Finance. All rights reserved"
					/>
				</p>
			</LimitedWidth>
		</div>
	);
};

export default BankeraSide;
