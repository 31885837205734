import classNames from 'classnames';
import React from 'react';
import { container, columnBelowDesktop } from './splitScreenContainer.module.scss';

interface Props {
	onlyOnDesktop?: boolean;
}

const SplitScreenContainer: React.FC<Props> = ({ children, onlyOnDesktop }) => {
	return <div className={classNames(container, { [columnBelowDesktop]: onlyOnDesktop })}>{children}</div>;
};

export default SplitScreenContainer;
