import React from 'react';
import LimitedWidth from 'src/components/LimitedWidth/LimitedWidth';
import logoSrc from 'src/images/vector/sc-logo.svg';
import cardSrc from 'src/images/raster/sc-card.png';
import listSrc1 from 'src/images/vector/sc-list-card.svg';
import listSrc2 from 'src/images/vector/sc-list-deposit-and-withdraw.svg';
import listSrc3 from 'src/images/vector/sc-list-iban.svg';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import richFormattingValues from 'src/helpers/RichFormattingValues';
import {
	logo,
	card,
	container,
	textBox,
	scButton,
	listItem,
	list,
	orderButton,
	footer,
} from './companySides.module.scss';
import CardInfoTable, { genericCardInfo } from '../CardInfoTable/CardInfoTable';

const SpectroCoinSide: React.FC = () => {
	return (
		<div className={container}>
			<img src={logoSrc} alt="SpectroCoin logo" className={logo} />
			<img src={cardSrc} alt="SpectroCoin card" className={card} />
			<LimitedWidth maxWidth={426} centerBlock>
				<p className={textBox}>
					<FormattedMessage
						id="sides.spectrocoin.introduction"
						defaultMessage="If you want to take your cryptocurrency spending to the next 
						level – order your free Visa debit card at SpectroCoin. Exchange Bitcoin, 
						Ethereum, Litecoin, and a number of other popular cryptos at competitive rates. 
						Pay with your card at millions of shops worldwide."
					/>
				</p>
				<a href="https://spectrocoin.com/" className={scButton}>
					<FormattedMessage id="sides.spectrocoin.button.card" defaultMessage="Get Your SpectroCoin Card" />
				</a>
				<div className={list}>
					<div className={listItem}>
						<div>
							<img src={listSrc1} alt="SpectroCoin card icon" />
						</div>
						<p>
							<FormattedMessage
								id="sides.spectrocoin.list.card"
								defaultMessage="<b>Get free of charge.</b> Order a Visa debit card for free and spend cryptocurrencies directly from your SpectroCoin wallet."
								values={richFormattingValues}
							/>
						</p>
					</div>
					<div className={listItem}>
						<div>
							<img src={listSrc2} alt="SpectroCoin deposit and withdraw icon" />
						</div>
						<p>
							<FormattedMessage
								id="sides.spectrocoin.list.depositAndWithdraw"
								defaultMessage="<b>Enjoy multiple deposit and withdrawal methods.</b> 
								Choose from a widerange of payment options, such as SEPA Instant and SWIFT bank wires, 
								linked card top-ups, e-wallets like Skrill and Payeer, and cryptocurrency transfers."
								values={richFormattingValues}
							/>
						</p>
					</div>
					<div className={listItem}>
						<div>
							<img src={listSrc3} alt="SpectroCoin iban icon" />
						</div>
						<p>
							<FormattedMessage
								id="sides.spectrocoin.list.iban"
								defaultMessage="<b>Claim a personal dedicated IBAN account.</b> 
								Link your cryptocurrency to fiat with a dedicated IBAN account and make payments in your own name."
								values={richFormattingValues}
							/>
						</p>
					</div>
				</div>
				<CardInfoTable rows={genericCardInfo} />
				<a href="https://spectrocoin.com/" className={classNames(scButton, orderButton)}>
					<FormattedMessage id="button.order" defaultMessage="Order now" />
				</a>
			</LimitedWidth>
			<p className={classNames(footer, 'showAboveTablet')}>
				<FormattedMessage
					id="footer.text"
					defaultMessage="Copyright © 2013-2021 Spectro Finance. All rights reserved"
				/>
			</p>
		</div>
	);
};

export default SpectroCoinSide;
