import React from 'react';
import { Helmet } from 'react-helmet';
import { BASE_URL } from 'gatsby-env-variables';

interface PageMetaData {
	title?: string;
	description?: string;
	keywords?: string;
	robots?: string;
	canonical?: string;
	og?: {
		title?: string;
		description?: string;
		image?: string;
		twitterImage?: string;
		url?: string;
		urlPathname?: string; // Used to construct og.url
	};
}

const defaultPageMetaData: PageMetaData = {
	title: 'Order card',
	description:
		'Order a free Visa debit card at SpectroCoin and get a physical or virtual debit card with a European IBAN account at Bankera.',
	og: {
		image: `${BASE_URL}/media/og.png`,
		twitterImage: `${BASE_URL}/media/og-wide.png`,
	},
};

const MetaTags: React.FC<{ data: PageMetaData }> = ({ data }) => {
	const mergedPageMetaData: PageMetaData = {
		...data,
		title: data.title || defaultPageMetaData.title,
		description: data.description || defaultPageMetaData.description,
		og: {
			title: data.og?.title || data.title || defaultPageMetaData.title,
			description: data.og?.description || data.description || defaultPageMetaData.description,
			image: data.og?.image || defaultPageMetaData.og?.image,
			twitterImage: data.og?.twitterImage || defaultPageMetaData.og?.twitterImage,
			url: data.og?.url || (data.og?.urlPathname && `${BASE_URL}${data.og?.urlPathname}`) || data.canonical,
		},
	};

	return (
		<Helmet>
			<title>{mergedPageMetaData.title}</title>
			<meta name="description" content={mergedPageMetaData.description} />

			{mergedPageMetaData.keywords && <meta name="keywords" content={mergedPageMetaData.keywords} />}
			{mergedPageMetaData.robots && <meta name="robots" content={mergedPageMetaData.robots} />}
			{mergedPageMetaData.canonical && <link rel="canonical" href={mergedPageMetaData.canonical} />}

			<meta property="og:title" content={mergedPageMetaData.og?.title} />
			<meta property="og:description" content={mergedPageMetaData.og?.description} />
			<meta property="og:image" content={mergedPageMetaData.og?.image} />
			{mergedPageMetaData.og?.url && <meta property="og:url" content={mergedPageMetaData.og?.url} />}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:image" content={mergedPageMetaData.og?.twitterImage} />

			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="crossorigin" />
			<link
				href="https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500&family=Red+Hat+Text:wght@400;500&display=swap"
				rel="stylesheet"
			/>
		</Helmet>
	);
};

export default MetaTags;
