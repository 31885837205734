import React from 'react';
import { FormattedMessage } from 'react-intl';
import { container } from './header.module.scss';

const Header: React.FC = () => {
	return (
		<div className={container}>
			<FormattedMessage
				id="header.text"
				defaultMessage="SpectroCard has moved and is now part of Bankera. Find out other options of physical and virtual cards below."
			/>
		</div>
	);
};

export default Header;
