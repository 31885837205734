// extracted by mini-css-extract-plugin
export var container = "companySides-module--container--15lMO";
export var bankeraBackground = "companySides-module--bankeraBackground--RmDS4";
export var centerBlock = "companySides-module--centerBlock--3cMZG";
export var listItem = "companySides-module--listItem--3E8Vx";
export var card = "companySides-module--card--3v1rV";
export var logo = "companySides-module--logo--3LQHp";
export var textBox = "companySides-module--textBox--wydYT";
export var button = "companySides-module--button--3aV3C";
export var bnkButton = "companySides-module--bnkButton--2vzxA";
export var scButton = "companySides-module--scButton--2fbv5";
export var orderButton = "companySides-module--orderButton--2ilPq";
export var list = "companySides-module--list--3ipLR";
export var footer = "companySides-module--footer--3bfIK";